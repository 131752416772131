<template>
    <div class="container d-margen">
        <div class="card ">
            <div class="card-body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>

</script>
<style>
div.d-margen {
    margin-top: 1%;
}
</style>