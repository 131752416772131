<template>
    <CardtoAll>
        404
    </CardtoAll>
</template>
<script>
import CardtoAll from "@/components/CardtoAll.vue"
export default {
    components: {
        CardtoAll
    }
}


</script>
<style scoped></style>